// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  margin: 'auto',
  minHeight: '50vh',
  display: 'flex',
  justifyContent: 'top',
  flexDirection: 'column',
  padding: theme.spacing(3, 0),
}));

// ----------------------------------------------------------------------

export default function Terms() {
  return (
    <Page title="Terms and Conditions">
      <RootStyle>
        <Container maxWidth="lg">
          <ContentStyle>
            <Typography variant="h2" gutterBottom>
              Terms and Conditions
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Last Updated: September 01, 2022</strong>
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              These Terms of Service govern your participation in and/or use of certain services or features of
              Quixo.net, a website owned and operated by Quixo.net {'(the "Website," "Quixo.net", "we" or "our")'}. By
              participating in or using the services or features of the Website, you {'("you", "your" or "User")'} are
              agreeing to be bound by these terms of service {'("Terms of Service")'}.
            </Typography>
            <br />
            <br />
            <Typography variant="h5">General Restrictions</Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <ol>
                <li>
                  Quixo.net reserves the right to terminate User's participation in the Website in its absolute and sole
                  discretion at any time.
                </li>
                <li>
                  Users may not generate traffic to their User website or our links by any of the following methods:
                  listings on newsgroups, bulk e-mailing, icq postings, IM messages, chatroom/irc postings, iframes,
                  zero pixel frames, hitbots, clickbots, spiders, cgi-scripts, JavaScript, DNS hacking, spoofing or
                  pharming, cache poisoning, any toolbar or downloading of any computer software application
                  {'("Downloadable App")'}, altering an End User's host file to point another domain to a User's domain,
                  PTC Websites, click farms, via cell phone messages, and other online incentives.
                </li>
                <li>
                  Users are prohibited from monetizing websites they do not own using the Website by all previously
                  mentioned methods.
                </li>
                <li> Users may not misrepresent their ownership of a website or domain name in any way.</li>
                <li> Users may not beg, ask, entice, or incentivize End Users into clicking on our links.</li>
                <li>
                  Users may not mislead End Users into believing that the End User will receive anything by clicking on
                  a text link or search box or otherwise encourage End Users, either with or without their knowledge, to
                  click on advertising results through offering incentives or any other methods that are manipulative,
                  deceptive, malicious or fraudulent, in the sole but reasonable judgment of Quixo.net.
                </li>
                <li>
                  Quixo.net reserves the right to terminate your account if it is idle for more than a month. Quixo.net
                  may notify you in advance of such cancellation.
                </li>
                <li>
                  User may not direct or redirect domains to the Quixo.net's servers in any manner which has not been
                  previously approved by Quixo.net. As such, Quixo.net reserves any and all rights to direct the method
                  by which User directs or redirects its domains to the Quixo.net's servers, whether through DNS,
                  redirect code or otherwise.
                </li>
              </ol>
            </Typography>
            <br />
            <br />
            <Typography variant="h5">Additional Restrictions.</Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              No User website shall: {'(a)'} provide any interface for a Downloadable App, any marketing materials for
              any Downloadable App, or any hyperlinks to any of the foregoing; {'(b)'} be a downloadable or Internet
              accessible application, as determined by Quixo.net in its sole reasonable discretion; {'(c)'} under any
              circumstances, be a competitor of any search engine company, as determined by Quixo.net in its sole
              reasonable discretion; {'(d)'} place, display or use any unauthorized branding or attribution of any kind
              on such User website, including without limitation on any Landing Page, Search Results Page or framed in
              conjunction with any such pages, to indicate that any search engine is providing such results or for other
              promotional purposes, {'(e)'} except to the extent provided by Quixo.net, contain any pornographic,
              hate-related or violent content or contain any other material, products or services that {'(i)'} violate
              or encourage conduct that would violate any criminal laws, any other applicable laws, or any third party
              rights, or {'(ii)'} would otherwise give rise to civil liability or {'(f)'} include content above or below
              the Landing Page, unless that content is submitted for approval in writing to Quixo.net and pre-approval
              in writing is received from Quixo.net, prior to any such content being displayed on the User website.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              In the event that any website is blocked from use on the Website by Quixo.net, such website may not
              participate, either directly or indirectly, in the Website without the express written consent of
              Quixo.net. Accordingly, neither User nor anyone acting on behalf of User shall redirect or attempt to
              redirect the website to the Website through use of embedded redirection or other method intended to avoid
              the blocking of such website from participation in the Website. Any failure to comply with the terms of
              this policy may result in an immediate termination of User's account and forfeiture of any commissions
              earned or to be earned by User hereunder.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Domain Names &amp; Intellectual Property Rights. </strong>User shall not place with Quixo.net or
              in any way through the Website a domain name to which it does not have valid ownership, as determined by
              Quixo.net in its sole discretion. In the event that any such domain name is provided for use in the
              Website, and such lack of rights or a claim of intellectual property infringement is brought to the
              attention of Quixo.net, then Quixo.net shall have the right to cancel the participation of any such domain
              name{'(s)'} in the Website, and, in appropriate situations, in the sole discretion of Quixo.net, terminate
              User's participation in the Website and seek legal recourse against User and any others involved in
              accordance with these Terms of Service and applicable law.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Representatives and Agents.</strong> If a User desires to have a representative or agent manage
              and control their account with Quixo.net, either individually or aggregated with other accounts managed by
              such representative or agent, User's use of such representative or agent shall be subject to the following
              terms: {'(i)'} such representative or agent must have agreed to be bound by these Terms of Service and
              otherwise must be in good standing with Quixo.net, as detailed below; {'(ii)'} User shall be deemed to
              have authorized such representative or agent to take any and all actions with respect to the User's
              account and participation of User's websites in the Website, including without limitation, being the
              authorized contact for all communications to and from Quixo.net and being authorized to make any and all
              changes with respect to User's account with Quixo.net and Quixo.net shall be fully entitled to rely on,
              and shall have no liability to User with respect to, any and all requests, decisions, actions and/or
              omissions of such representative or agent with respect to User's account and/or websites; and {'(iii)'}
              User's commission payments and User's account with Quixo.net may be suspended, cancelled or terminated if
              such representative or agent violates these Terms of Service with respect to any account or websites name
              participating in the Website which is under its control, even if such breach is unrelated to such User's
              account. If User desires to remove its representative or agent from its account, it must do so by
              delivering written notice to Quixo.net specifically revoking the authority of such representative or agent
              from such User's account, such revocation not to be effective until written confirmation from Quixo.net of
              receipt of its written request. User acknowledges and agrees that in the event User's representative or
              agent is removed as provided herein, Quixo.net may change any and all terms applicable to User's
              commissions, including the rate, payment thresholds and other payment terms to be consistent with other
              individual Users' accounts, and that User's account may be subject to cancellation if User does not meet
              minimum performance standards as an individual account.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              For purposes of this Agreement, a User or representative shall be in "good standing" if such User
              {
                '(or if a representative, then such representative and each customer of the representative who would be Users hereunder)'
              }
              is not currently in violation of these Terms of Service, has not been in repeated violation of these Terms
              of Service, even if such past violations have been cured, and has not been subjected to excessive Revenue
              deductions or adjustments in Quixo.net's discretion.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Each representative or agent that manages and/or controls accounts or websites participating in the
              Website for which they are not the owner agrees to be bound by these Terms of Service for all such
              accounts and websites. Each such representative or agent acknowledges and agrees that {'(i)'} a violation
              of these Terms of Service with respect to any one account managed or controlled by it shall constitute a
              violation of these Terms of Service for all accounts managed or controlled by such representative or agent
              and may result in all of such accounts being suspended or terminated, {'(ii)'} in the event that Quixo.net
              receives complaints or other communications from the owner of accounts or websites managed by such
              representative or agent expressing their dissatisfaction with such representative or agent, Quixo.net may,
              in its sole and absolute discretion, revoke such representative's or agent's status as a representative
              for accounts or websites with Quixo.net and Quixo.net may work directly with such representative's or
              agent's clients and {'(iii)'} it shall treat all confidential information of a managed account
              confidential and that disclosure of such information to anyone other than Quixo.net or the client shall
              constitute a violation of such client's privacy and may result in Quixo.net revoking such representative
              or agent's status as a representative for accounts or websites participating in the Website.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Account Access.</strong> When a User uses the services provided by Quixo.net, User will be
              required to create an account with Quixo.net to use its web-based interface. User is responsible for
              selecting and continuously managing his, her or its password and security settings to protect User's
              account and User's website monetization settings {"(including User's contact information)"} from
              unauthorized changes. User is entirely responsible for maintaining the confidentiality and secrecy of
              User's password and account security settings, as well as User's other information. All consequences of
              User's voluntary disclosure of password and account information as well as all activities that occur in
              User's account are User's responsibility.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              User agrees to notify Quixo.net immediately of any unauthorized use of User's account or any other breach
              of security. User agrees that it will be responsible for all activity that arises from User's account,
              whether initiated by User or others on User's behalf and Quixo.net shall be entitled to rely on any
              requests which have been initiated from User's account. Quixo.net disclaims any liability for any activity
              in User's account, whether initiated or authorized by User or not.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Additional Definitions.</strong> As used herein, the following capitalized terms shall have the
              meanings provided as follows:
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              "Clicks" means a click by an End User. "Clicks" do not include clicks which are invalid as determined by
              Quixo.net's internal tracking system and traffic quality scoring process or for which Quixo.net does not
              recognize revenue from its advertiser partners. Invalid Clicks may include clicks {'(i)'} generated by
              automated crawlers, robots or click generating scripts providing traffic which has no intent to sign-up,
              purchase or otherwise act upon a product or service offering; {'(ii)'} multiple clicks on the same
              advertisement within a brief period of time, or {'(iii)'} that an advertiser receives and rejects for a
              valid business purpose {'(e.g., a Click which was not generated by an End User)'}.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              "User website" means any website associated with a domain name owned by a User which participates in the
              services or features of the Website.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              "End User" means a bona fide living human Internet user. An "End User" excludes any person or any
              mechanical, artificial or fraudulent means {'(such as a so-called robot, spider, software or scraper)'}
              lacking an intention to purchase, sign up or otherwise act upon a product or service of the marketer
              contained on a Landing Page.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              "Landing Page" means an html page or a framed html page hosted by or on behalf of Quixo.net, content from
              Quixo.net servers or such servers as Quixo.net uses.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              "Net Revenue" means Revenue adjusted for any bad debt, net credits or returns from previous payment
              periods, less any amount Quixo.net determines, in its sole discretion, was the result of improper use of
              User websites and as such revenue is otherwise calculated or adjusted by Quixo.net to take into account
              {'(i)'} any exclusions set forth in these Terms of Service, {'(ii)'} the results of Quixo.net's traffic
              quality scoring process and {'(iii)'} any other adjustments made by Quixo.net in its sole discretion.
              Quixo.net may exclude from Net Revenue monies from traffic that originates from countries where traffic
              is, in the sole discretion of Quixo.net, suspect or difficult to track in the normal course of business or
              is determined to be invalid on a consistent and regular basis by Quixo.net or its Advertiser.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              "Revenue" means estimated gross revenue to be received by Quixo.net for a particular calendar month as a
              direct result of Clicks on an advertisement or other promotional link attributable to traffic from Landing
              Pages or webpages hosted on a User website pursuant to the Website as reported by Quixo.net's Advertiser
              as of the end of such calendar month.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Consequences of Non-Compliance.</strong> User acknowledges that, in addition to any other remedies
              at law that Quixo.net may have, failure by User to comply with any or all of the terms in these Terms of
              Service will result in {'(i)'} the forfeiture by User of any yet unpaid commissions and/or {'(ii)'} the
              obligation to pay additional compensation from future earnings, over time, to compensate for the monies
              paid out by Quixo.net plus applicable interest, limited to 5% per annum of the principal, accrued monthly.
              Future earnings payment applies only if User is a still existing client. Monies due will be based solely
              on payments made on such websites with issues of violations for the time period that User's websites
              subject to the Website were in violation of the terms plus any applicable interest, as stated above.
              Quixo.net shall make the determination of length of time and value of any violation of the terms herein,
              solely and such determinations will be final. User may present information to the contrary up to fifteen
              {'(15)'} calendar days after notification to User of the beginning of the investigation. User agrees to
              hereby accept Quixo.net's decisions regarding such violations of the Terms of Service as final and wave
              any litigation rights, whatsoever, of any issue, compensation or otherwise relating to the decision and/or
              Terms of Service violations. User or Quixo.net may terminate these Terms of Service at any time. Quixo.net
              reserves the right to access any outstanding balance payments, in its sole discretion, if User, its
              subsidiary, or related company were to reapply to be a User using the Website at any time in the future.
              Finally, such failures may also result in the termination of User's account.
            </Typography>

            <br />
            <br />
            <Typography variant="h5">General Terms</Typography>

            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>2.1 Representations &amp; Warranties</strong>
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Quixo.net represents and warrants that it has full power and authority to enter into these Terms of
              Service. User represents and warrants that {'(i)'} it has full power and authority to agree to these Terms
              of Service, and {'(ii)'} it owns or has the right to use all necessary rights to the User domain names and
              User websites.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              THE WEBSITE, QUIXO.NET AND ITS AFFILIATES AND ITS AND THEIR RESPECTIVE OFFICERS, DIRECTORS, SHAREHOLDERS,
              MEMBERS, EMPLOYEES, ADVISORS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, VENDORS, ADVERTISERS AND
              AGENTS {'(COLLECTIVELY THE "WEBSITE PARTIES")'} ARE NOT RESPONSIBLE FOR ANY CONTENT PROVIDED BY THIRD
              PARTIES {'(INCLUDING ADVERTISERS)'}. EXCEPT AS EXPRESSLY SET FORTH HEREIN, NONE OF THE WEBSITE PARTIES
              MAKES ANY REPRESENTATION OR WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE,
              INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE, NONINFRINGEMENT
              AND ANY IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING OR COURSE OF PERFORMANCE. THE ENTIRE RISK
              ARISING OUT OF THE USE OF THE WEBSITE OR PERFORMANCE OF THE USER websiteS REMAINS WITH USER. WITHOUT
              LIMITING THE GENERALITY OF THE FOREGOING, THE WEBSITE PARTIES SPECIFICALLY DISCLAIM ANY REPRESENTATION OR
              WARRANTY REGARDING {'(A)'} THE SUCCESS OF THE WEBSITE, {'(B)'} THE NUMBER OF END USERS, IF ANY, WHO MAY
              SEE ADVERTISEMENTS THROUGH THE WEBSITE, AND {'(C)'} THE ERROR-FREE, DISRUPTION-FREE OR INTERRUPTION-FREE
              NATURE OF THE WEBSITE. THE WEBSITE IS PROVIDED "AS IS", "WITH ALL FAULTS" AND "AS AVAILABLE" WITHOUT
              WARRANTY, OF ANY KIND, AND ANY USE BY YOU SHALL BE AT YOUR SOLE RISK. TO THE MAXIMUM EXTENT PERMITTED BY
              APPLICABLE LAW, IN NO EVENT SHALL WEBSITE PARTIES BE LIABLE FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT,
              SPECIAL, PUNITIVE, OR OTHER DAMAGES WHATSOEVER
              {
                '(INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR OTHER PECUNIARY LOSS)'
              }
              ARISING OUT OF OR RELATING TO THESE TERMS OF SERVICE OR THE USE OF OR INABILITY TO USE THE WEBSITE, EVEN
              IF QUIXO.NET HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE TOTAL, CUMULATIVE LIABILITY OF THE
              WEBSITE PARTIES FOR ANY DIRECT DAMAGES INCURRED HEREUNDER SHALL NOT EXCEED FIFTY DOLLARS {'($50.00)'}.
              BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
              OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO USER.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>2.2 Intellectual Property</strong>
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              As between Quixo.net and User, Quixo.net {'(or others it so designates in writing)'} shall own all right,
              title and interest, including without limitation all Intellectual Property Rights {'(as defined below)'},
              relating to the Website {'(and any derivative works or enhancements thereof)'}, including but not limited
              to, all software, technology, processes, materials, guidelines, documentation, relating in any way to the
              Website. For purposes of these Terms of Service, "Intellectual Property Rights" means any and all rights
              existing from time to time under patent law, copyright law, moral rights law, trade secret law, trademark
              law, unfair competition law, publicity rights law, privacy rights law, and any and all other proprietary
              rights, as well as, any and all applications, renewals, extensions, restorations and re-instatements
              thereof, now or hereafter in force and effect worldwide. User shall not, and shall not allow any third
              party to: {'(i)'} modify, adapt, translate, prepare derivative works from, decompile, reverse engineer,
              disassemble or otherwise attempt to derive source code from any intellectual property in any way related
              to the Website or Quixo.net; {'(ii)'} affix any unauthorized copyright notice, trademarks or other
              proprietary rights notices affixed to or provided as a part of any Website, or any other technology,
              software, materials and documentation related to the Website or Quixo.net; {'(iii)'} crawl, index or in
              any non-transitory manner store or cache information obtained from the Website; {'(iv)'} transfer, sell,
              lease, lend, disclose, or use for co-branding without explicit written permission from Quixo.net, any
              aspect of the Website under any timesharing, service bureau or other unauthorized method or access
              thereto; {'(v)'} directly or indirectly access, launch and/or activate the Website through or from any
              software application or means other than the User website; or {'(vi)'} engage in any action or practice
              that reflects poorly on the Website, Quixo.net or its Advertisers, or otherwise disparages or devalues the
              reputation or goodwill of the same.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>2.3 Confidentiality</strong>
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              User agrees to safeguard and, except for the benefit of the Website, not to disclose to anyone outside the
              Website any proprietary or confidential information acquired from User's access to the Website. Such
              information includes, without limitation, business plans, customer lists, operation procedures, trade
              secrets, design formulas and programming code, know-how and processes, computer programs and inventions,
              discoveries, and improvements of any kinds.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>2.4 Modifications</strong>
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Quixo.net reserves the right to change these Terms of Service at any time in its sole discretion and
              pursuant to applicable law. Amended versions of this Agreement will be effective upon posting at
              www.Quixo.net, and when such changes are deemed to be material by Quixo.net, email notice of such amended
              version will be sent to User. Your continued use of the Website after the effective date of any such
              changes will constitute your acceptance of and agreement to such changes. IF YOU DO NOT WISH TO BE BOUND
              TO ANY NEW TERMS, YOU MUST TERMINATE THESE TERMS OF SERVICE BY IMMEDIATELY CEASING USE OF THE WEBSITE.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Quixo.net agrees to use commercially reasonable efforts to minimize unscheduled modification and
              maintenance interruptions. However, Quixo.net reserves the right to modify or maintain the Website at any
              time, with or without notice to User.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>2.5 Indemnification</strong>
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              User shall indemnify, defend, and hold harmless the Website Parties from and against all liability,
              demands, claims, costs, losses, damages, recoveries, settlements, and expenses
              {'(including interest, penalties, reasonable attorney fees, accounting fees, and expert witness fees)'}
              incurred by the Website Parties, known or unknown, contingent or otherwise, directly or indirectly arising
              from User's breach of any term or provision of these Terms of Service or any way related to User's use of
              the Website, including but not limited to any claim that the website is owned by someone other than User
              or that the website infringes on the intellectual property rights of a third party. Quixo.net shall notify
              User of any such claim and shall cooperate with User, at User's expense, in defending or settling such
              claim. User may not settle any such claim in a manner that imposes any non-indemnified costs or otherwise
              adversely affects Quixo.net rights without Quixo.net's prior written consent. Quixo.net may join in
              defense with counsel of its choice at its own expense. If User does not assume the defense of any such
              claim within thirty {'(30)'} days after the date notice of such claim is given, Quixo.net may defend
              against such claim in such manner as it may deem appropriate at User's expense, including, without
              limitation, settling such claim, after giving notice of the same to User.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>2.6 Privacy Policy</strong>
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              The Website's Privacy Policy is hereby incorporated by reference into these Terms of Service. The Privacy
              Policy provides rights to User and Quixo.net's responsibilities with regard to User' personal information.
              Quixo.net shall not use information of a User in any way inconsistent with the purposes and limitations
              provided in the Privacy Policy. Quixo.net may update, modify or amend the Privacy Policy from time to time
              as set forth therein.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>2.7 Miscellaneous</strong>
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Governing Law.</strong> These Terms of Service shall be governed by, and construed in accordance
              with, the laws of the State of California, without giving any effect to any choice of law provisions
              thereof that would cause the application of the laws of any other jurisdiction. The United Nations
              Convention on Contracts for the International Sale of Goods shall not apply to these Terms of Service.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Jurisdiction and Venue.</strong> Any litigation based hereon, or arising out of, under, or in
              connection with these Terms of Service, shall be brought and maintained exclusively in the courts of the
              State of California or in the United States District Court for the District of California. The parties
              hereto hereby expressly and irrevocably submit to the jurisdiction of the courts of the State of
              California and of the United States District Court for the District of California for the purpose of any
              such litigation as set forth above. Each party further agrees that service of any process, summons, notice
              or document by U.S. certified mail to such party's respective postal address as set forth in the notice
              section shall be effective service of process for any action, suit or proceeding in the state and federal
              courts located in the State of California, with respect to any matters to which it has submitted to
              jurisdiction as set forth above. In addition, each party irrevocably and unconditionally waives
              application of the procedures for service of process pursuant to the Hague Convention for Service Abroad
              of Judicial and Extrajudicial Documents in Civil or Commercial Matters. The parties hereto hereby
              expressly and irrevocably waive, to the fullest extent permitted by law, any objection which it may now or
              hereafter have to the laying of venue of any such litigation brought in any such court referred to above
              and any claim that any such litigation has been brought in an inconvenient forum.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Waiver of Jury Trial.</strong> Each of the parties hereto hereby waives, to the fullest extent
              permitted by applicable law, any right to a trial by jury in any action or proceeding to enforce or defend
              any rights under these Terms of Service and any amendment, instrument, document or agreement delivered or
              which may in the future be delivered in connection herewith or therewith, and agrees that any such action
              or proceeding shall be tried before a court and not before a jury.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>No Third Party Beneficiaries.</strong> These Terms of Service are made solely for the benefit of
              User and the Website Parties and their respective successors and assigns, and no other person or entity
              shall have or acquire any right by virtue of these Terms of Service.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Prohibition of Assignment.</strong> User may not assign any of its rights or delegate any of its
              duties under these Terms of Service without the prior written consent of the Quixo.net. Despite such
              consent, no assignment shall release the assignor of any its obligations or alter any of its primary
              obligations to be performed under these Terms of Service.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Successors and Assigns.</strong> Except as otherwise expressly provided in these Terms of Service,
              these Terms of Service shall bind and inure to the benefit of the successors, assigns, heirs, executors
              and administrators of the parties to these Terms of Service.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Independent Contractor.</strong> The relationship of User and Quixo.net established by these Terms
              of Service is that of independent contractors, and neither party is an employee, agent, partner or joint
              venturer of the other.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Force Majeure.</strong> Neither party shall be deemed in default of this Agreement to the extent
              that performance of its obligations or attempts to cure any breach are delayed or prevented by reason of
              any act of God, fire, natural disaster, accident, terrorism, riot, act of government, shortage of
              materials or supplies, or any other cause beyond the reasonable control of such party.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Waiver.</strong> No waiver of a breach, failure of any condition, or any right or remedy contained
              in or granted by the provisions of these Terms of Service shall be effective unless it is in writing and
              signed by the party waiving the breach, failure, right, or remedy. No failure or delay by Quixo.net in
              exercising any right, power or privilege hereunder shall operate as a waiver thereof nor shall any single
              or partial exercise thereof preclude any other or further exercise thereof or the exercise of any other
              right, power or privilege. The rights and remedies herein provided shall be cumulative and not exclusive
              of any rights or remedies provided by law.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Entire Agreement.</strong> Except as may be set forth in an written agreement signed between
              Quixo.net and User, these Terms of Service constitute the final, complete, and exclusive statement of the
              terms of the use of the Website between the parties and supersedes all prior and contemporaneous
              understandings or agreements of the parties.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Severability.</strong> If a court or an arbitrator of competent jurisdiction holds any provision
              of these Terms of Service to be illegal, unenforceable, or invalid in whole or in part for any reason, the
              validity and enforceability of the remaining provisions, or portions of them, will not be affected.
              Notwithstanding the foregoing, if such provision could be more narrowly drawn so as not to be invalid,
              prohibited or unenforceable in such jurisdiction, it shall, as to such jurisdiction, be so narrowly drawn,
              without invalidating the remaining provisions of these Terms of Service or affecting the validity or
              enforceability of such provision in any other jurisdiction.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Attorneys Fees.</strong> If Quixo.net prevails in any action, suit, or proceeding arising from or
              based upon these Terms of Service, Quixo.net shall be entitled to recover from User Quixo.net's reasonable
              attorneys' fees in connection therewith in addition to the costs of such action, suit, or proceeding.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Headings.</strong> The headings in these Terms of Service are included for convenience only and
              shall neither affect the construction or interpretation of any provision in these Terms of Service nor
              affect any of the rights or obligations of the parties these Terms of Service.
            </Typography>
            <br />
            <br />
            <Typography variant="h5">SUBSCRIPTION TERMINATION.</Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Unless terminated earlier in accordance with this Section, this Agreement will begin on the Effective Date
              and will continue until you cancel your automatic subscription in your Paypal account. You authorize
              Quixo.net to automatically renew the applicable Services upon the expiration of the Initial Term
              {'(each a Renewal Term, and collectively with the Initial Term, the Term)'}. The Renewal Term will be the
              same length as the Initial Term unless otherwise specified by Quixo.net at the time of renewal. By signing
              up to the subscription you agree to pay the monthly fee once the free trial expires if you didn't cancel
              your subscription at Paypal. If you don't wish to be charged, you must cancel your subscription during
              your trial period. You may cancel your subscription at any time by logging into your Paypal account and
              cancelling the billing agreement. It is your full responsibility to cancel your preapproved payment.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Notice.</strong> Any notice, communication or statement relating to these Terms of Service shall
              be in writing and deemed effective: {'(i)'} upon delivery when delivered in person; {'(ii)'} upon
              transmission when delivered by verified facsimile or other confirmed electronic transmission; {'(iii)'}
              when delivered by certified mail, postage prepaid, return receipt requested or by nationally-recognized
              overnight courier service {'(including but not limited to Federal Express)'} to the address of the
              respective party as indicated herein {'(or in the case of User, as last provided to Quixo.net)'}; {'(iv)'}
              in the event of non-material changes to this Agreement, notice shall be deemed effective upon posting at
              www.Quixo.net; {'(v)'} and in the event of material changes to this Agreement, notice shall be effective
              upon the sending of an email from Quixo.net to the email address last provided by User.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Survival.</strong> Sections 2.1, 2.3, 2.5, 2.7, and any other obligations which expressly or by
              their nature are to continue after termination, cancellation, or expiration of these Terms of Service
              shall survive and remain in effect after such happening.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Interpretation.</strong> Each party acknowledges and agrees that it has had the opportunity to
              seek the advice of independent legal counsel and has read and understood all of the terms and conditions
              of these Terms of Service. These Terms of Service shall not be construed against either party by reason of
              its drafting.
            </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
