import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import MainLayout from './layouts/main';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import LandingPage from './pages/LandingPage';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';

import GenerateBlogTitle from './pages/generate/BlogTitle';
import GenerateBlogIntro from './pages/generate/BlogIntro';
import GenerateBlogOutline from './pages/generate/BlogOutline';
import GenerateProductDescription from './pages/generate/ProductDescription';
import GenerateSummarize from './pages/generate/Summarize';
import GenerateExplainIt from './pages/generate/ExplainIt';
import GenerateExtractKeywords from './pages/generate/ExtractKeywords';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'blog/title', element: <GenerateBlogTitle /> },
        { path: 'blog/intro', element: <GenerateBlogIntro /> },
        { path: 'blog/outline', element: <GenerateBlogOutline /> },
        { path: 'ecommerce/product-description', element: <GenerateProductDescription /> },
        { path: 'rewrite/summarize', element: <GenerateSummarize /> },
        { path: 'rewrite/explain-it', element: <GenerateExplainIt /> },
        { path: 'rewrite/extract-keywords', element: <GenerateExtractKeywords /> },
        { path: 'user', element: <User /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: 'home', element: <LandingPage /> },
        { path: 'about-us', element: <NotFound /> },
        { path: 'terms', element: <Terms /> },
        { path: 'privacy', element: <Privacy /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
