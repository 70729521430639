// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  margin: 'auto',
  minHeight: '50vh',
  display: 'flex',
  justifyContent: 'top',
  flexDirection: 'column',
  padding: theme.spacing(3, 0),
}));

// ----------------------------------------------------------------------

export default function Privacy() {
  return (
    <Page title="Privacy Policy">
      <RootStyle>
        <Container maxWidth="lg">
          <ContentStyle>
            <Typography variant="h2" gutterBottom>
              Privacy Policy
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              <strong>Last Updated: September 01, 2022</strong>
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Thank you for visiting the Quixo.net website located at the https://quixo.net domain or our advertising
              platform located at the https://quixo.net domain. This privacy policy (&ldquo;Policy&rdquo;) describes how
              personal information you voluntarily provide or authorize to be released, or which is publicly available
              or lawfully obtainable from third parties, may be obtained and used by Quixo.net and its online
              advertising and monetization operations (collectively, &ldquo;Quixo.net&rdquo;, &ldquo;we&rdquo; or
              &ldquo;our&rdquo;). Quixo.net provides a technology platform that enables the delivery of targeted
              advertisements on behalf of its advertising clients (&ldquo;Advertisers&rdquo;) to consumers who have
              registered, utilized the products of, or otherwise interacted with one of the third parties in Quixo.net's
              User network of third party websites, social media pages, software applications, social networks and other
              Internet properties (together, these third party sources are referred to in this Policy as "Users", and
              their websites, pages, applications, networks and other Internet properties are referred to as &ldquo;Web
              Properties&rdquo;). If you are a user of Quixo.net's website located at www.Quixo.net or any of the
              materials, products or services provided on or through the website (collectively, the &ldquo;Site&rdquo;),
              or if you are one of Quixo.net's Users or Advertisers, you agree to this Policy, in its entirety, when
              you: (a) access or use the Site; or (b) register on the Site to participate as a User or Advertiser on the
              Quixo.net Network (as this term is defined in the Quixo.net Website Terms and Conditions posted on the
              Site (&ldquo;General Terms&rdquo;)). If you do not agree to this Policy in its entirety, you are not
              authorized to become an Advertiser or other user (collectively, a &ldquo;Quixo.net Member&rdquo;) and/or
              to use the Site in any manner or form whatsoever. Additionally, this Policy is provided for informational
              purposes so that customers and users of Quixo.net's products and services can better understand how
              Quixo.net collects, discloses and uses personal information about them.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              This Policy does NOT apply to the practices of Advertisers, Users or other companies and persons that
              Quixo.net does not own or control or to persons that Quixo.net does not employ or manage. We reserve the
              right to change the terms of this Policy at any time. When we make changes, we will revise the "last
              updated" date at the top of the Policy and post the changed policy on the Site. If there are material
              changes to this Policy or in how Quixo.net will use your personal information, we will notify you by
              prominently posting a notice of such changes here or on our home page, or by sending you an e-mail (if you
              have registered with us). Your provision of any information on or through the Site following such
              notification shall constitute your consent to the collection, disclosure and use of such information in
              accordance with the changed Policy. Accordingly, we encourage you to review this Policy whenever you visit
              the Site in order to familiarize yourself with the most recent version. Furthermore, Quixo.net retains its
              right to enter into new or different lines of business with consumers and, if and to the extent it does
              so, may display a different Privacy Policy to the consumer for such lines of business.
            </Typography>
            <br />
            <br />
            <Typography variant="h5">Introduction</Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              <p>
                Our Policy is intended to explain our privacy practices concerning information about you, including:
              </p>
              <br />
              <ul>
                <li>The kinds of personal information we collect;</li>
                <li>How we use personal information;</li>
                <li>Disclosure of personal information;</li>
                <li>
                  How you can access, update and delete your personal information, or opt out from Quixo.net's
                  behavioral advertising;
                </li>
                <li>How we protect personal information.</li>
              </ul>
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              <p>
                Users of the Site and its products and services, and other persons who may be affected by Quixo.net's
                behavioral advertising and monetization operations, generally fall into three categories:
              </p>
              <br />
              <ol>
                <li>Users</li>
                <li>Advertisers</li>
                <li>
                  Consumers who receive and/or interact with targeted advertisements displayed when using services
                  provided by our Users ("Individuals").
                </li>
              </ol>
            </Typography>
            <br />
            <br />
            <Typography variant="h5">Information We Collect</Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              The following is a summary of the types of personal and non-personal information we collect from or about
              Quixo.net Members, Site users and Individuals. We will NOT collect personal information about you in a
              manner inconsistent with your personal profile privacy settings. If you do not wish to have certain
              personal information shared with us, please change your privacy settings with our Users or their networks.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Quixo.net Members and Voluntarily Provided Information. We collect personal information on the Site when
              you attempt to register as a Quixo.net Member or otherwise choose to provide personal information to us.
              Personal information is any information that can be used to identify or locate a particular person or
              entity. This may include, but is not limited to: personal name and business entity name and/or title with
              the applicable business entity, as well as your personal and/or business e-mail address, mailing address,
              daytime and/or mobile telephone numbers, fax number, PayPal or bank account information (including, but
              not limited to, account number, routing number and bank location), and/or any other information requested
              on the applicable Quixo.net Member registration form, such as username and password. (For purposes of this
              Policy, your bank account and other financial information shall be considered "Sensitive Information.") We
              also may collect certain non-personal information from Users, such as information typically contained in a
              web server's log files or analytics program including: IP address, browser type, operating system,
              geographic location, referral information, click-stream data, etc. (for additional detail about these
              practices, please see the section below entitled &ldquo;Individual and Quixo.net Member Behaviors
              Collected With Cookies, Web Beacons and Other Web Technologies.&rdquo;) Finally, we collect server and
              Individual activity information so that we can process and facilitate transactions in, or the awarding or
              crediting of, User virtual currencies as requested by Individuals and Users.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Individuals: Subscription or Survey Information. Quixo.net collects information from Individuals when you
              voluntarily provide it to Quixo.net or one of its Users (for example, by signing up for an email
              newsletter or through a co-registration offer on a survey), or by other means of online or offline
              surveys, order forms, or registration pages operated by third parties (collectively, a "User Form"). In a
              User Form, Quixo.net or a third party may offer you incentives in exchange for providing information about
              yourself, such as name, email address, street address, zip code, telephone numbers, birth date, gender,
              salary range, education and marital status, occupation, employment information, and personal and/or online
              interests (together, "User Information")). Completing the User Forms is voluntary and you are under no
              obligation to provide User Information.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Individuals: Information Collected When You Interact with Advertisements. When you interact with
              advertisements displayed using our technology, we may obtain certain personal information about you so
              that we can provide our services. For example, we may collect any information contained in your profile
              that you have voluntarily agreed to share, including your name, age, gender, and profile picture.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Individuals: Virtual Currency Transactions. In connection with providing our virtual currency monetization
              platform, through which we offer technology and services to enable Individuals using User Web Properties
              to earn points or other virtual currencies offered by the Users by responding to advertisements, and to
              facilitate direct payments from such Individuals via credit card, stored value or debit card, PayPal
              transfer, bank transfer, mobile billing and other payment sources, we may collect personal information,
              including name; email address; PayPal, credit, debit or stored value card, bank account and similar
              financial information (for purposes of this Policy, such financial information shall be considered
              &ldquo;Sensitive Information&rdquo;); information relating to your account with a User; and information
              concerning whether you have completed a User Form, made a purchase or completed some other action in
              response to an advertisement.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Individuals: Vendor List Information. Quixo.net may obtain information about you after you provide
              information to a third party and you permit that party (the "Seller") to share the information, which
              Quixo.net subsequently purchases, licenses or otherwise lawfully acquires. Such information may include,
              but is not limited to, your name, street address, zip code, telephone numbers, birth date, gender, salary
              range, education, marital status, occupation, industry of employment, personal and/or online interests,
              and/or other information which you provided to the Seller (together, "Vendor List Information"). When
              acquiring Vendor List Information, Quixo.net requires assurances from the Seller that the Seller has a
              right to transfer the Vendor List Information to Quixo.net and that Quixo.net may lawfully use the Vendor
              List Information to transmit offers from Advertisers.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Individual and Quixo.net Member Behaviors Collected with Cookies, Web Beacons, and Other Web Technologies.
              Quixo.net uses cookies, web beacons and other web-based technologies to associate certain Internet-related
              information about you, such as your Internet Protocol address and what web browser and operating system
              you are using, with certain of your online behaviors, such as opening or clicking on a particular online
              advertisement or visiting a particular page on the Site. Additionally, Quixo.net may use other new and
              evolving sources of information and information gathering in the future (together with cookies, web
              beacons and similar technologies, "Web Technologies"). We may use Web Technologies to collect certain
              non-personal information about Individuals to help us ensure that the advertisements we serve are
              targeted. For example, we may collect, without limitation, information related to advertisements that were
              delivered to you and that you clicked on, along with your browsing behavior and other click-stream data.
              Information collected through Web Technologies is referred to below as &ldquo;Technology
              Information.&rdquo;
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              (a) Cookies. A cookie is a small amount of data stored on the hard drive of your computer that allows your
              computer's browser to store your preferences and allows Quixo.net to identify your IP address with
              corresponding information in Quixo.net's database. Cookies, by themselves, do not tell us your email
              address and similar individual identifying information, but may contain such information as date and time
              of your website visit, session identification number and registration information. We use cookies to
              understand Site usage and to improve the content and offerings on the Site. In addition, Quixo.net may set
              a cookie on your computer when you view advertisements delivered on or in connection with User Web
              Properties. After a cookie is set, Quixo.net may track how you interact with the advertisement and the
              User Web Property (for example, registering, purchasing, browsing, etc.).
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              You may set your browser to warn you that cookies are in use, or to block the use of cookies. Most
              browsers are set to accept cookies. If your browser is not set to accept cookies, certain uses of the Site
              may require you to go to your browser user preferences to enable cookies. Each browser is different, so
              check the ''Help'' menu of your browser to learn how to change your cookie preferences. If you change
              computers, operating systems or browsers, or use multiple computers or browsers, you will need to repeat
              this process for each computer and each browser. To find out more about cookies, please visit
              www.cookiecentral.com.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              (b) Web beacons. A web beacon is a programming code used to display an image on a web page and can also
              transfer an individual's user identification to a database and associate the individual with previously
              acquired information about the individual. Web beacons enable Quixo.net to track certain websites you
              visit or advertisements you view online. Web beacons help determine products or services in which you may
              be interested and help track online behaviors for marketing purposes. More information about web beacons
              is available at
              <a href="http://www.webopedia.com/TERM/W/Web_beacon.html">
                http://www.webopedia.com/TERM/W/Web_beacon.html
              </a>
              .
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              (c) Where Technology Information is Collected. Technology Information is collected directly from the Site
              and Quixo.net-delivered advertisements or indirectly through Users with whom Quixo.net contracts to
              display advertisements.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              (d) Advertisement Codes. To make certain that our advertisements served to Web Properties by Quixo.net are
              targeted correctly, Users must place Quixo.net-provided tags or tracking codes, known as advertisement
              codes, on or in connection with their Web Properties. The Advertisement Codes do not collect personal
              information. They are used to match Individual browsing behavior to a segment profile designated for
              targeting Ads to Individuals on or using the applicable User Web Property.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Individuals: Email Databases. Quixo.net may track your interaction with email advertisements sent to you
              by Quixo.net or others (for example, when you register, purchase, or browse in response to such emails).
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Outside Information. Quixo.net may lawfully receive information about you from third parties, such as
              information located in public databases.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              No Information Collected from Children Under 13. Please be advised that we do not knowingly collect
              personal information from children under age 13. If we become aware that a child under age 13 has provided
              us with personal information, we will delete it from our database. If you are a parent or legal guardian
              and believe that your child has provided us with personal information without your consent, please contact
              us at legal@Quixo.net.com.
            </Typography>
            <br />
            <br />
            <Typography variant="h5">How We Use Your Information: Users and Advertisers</Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              We use your personal information primarily for administrative purposes pertaining to your account and
              customer management purposes, including (a) processing payments owed to you; (b) providing reports,
              customer service, newsletters, RSS feeds and other information, products and/or services that you have
              signed up for or have otherwise agreed to receive; (c) operating the Quixo.net Network; (d) sending you
              information regarding Quixo.net and/or the Quixo.net Network; (e) displaying content that is customized to
              your interests and preferences; (f) tracking with your compliance with the General Terms, and/or the
              Quixo.net Platform Services Agreement, as applicable; (g) contacting you when necessary regarding your
              account with us and/or operational updates to our Site; and (h) for validation, suppression, content
              improvement and feedback purposes. Unless you indicate a preference not to receive such information when
              you sign up for the Site or subsequently, we may also send you surveys, marketing communications to inform
              you of new products or services or other information or offers that may be of interest, and/or updates
              about Quixo.net. These surveys, marketing communications, or updates may come from Quixo.net or our
              service providers. If you do not wish to continue to receive surveys, marketing communications or updates,
              you may indicate this preference by following the "unsubscribe" or "opt-out" instructions included within
              each email communication. Please keep in mind that, even if you choose not to receive these
              communications, you may continue to receive transactional or account communications (e.g., confirmation
              emails) and other communications which are required or permitted by applicable law.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              We use Sensitive Information only to process, and provide support, servicing and fraud detection for,
              payments and other transactions that Users have requested.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              You also agree that we may also use your name, company name and logo in advertising or promotional
              materials to refer truthfully to you as an advertising client of ours or as a User in the Quixo.net
              Network.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              We use non-personal information primarily for internal research and analytics to improve our Site and our
              services.
            </Typography>
            <br />
            <br />
            <Typography variant="h5">How We Use Your Information: Individuals</Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              We use your personal information to help us ensure that the advertisements we serve are interesting to
              people who see the advertisements. For example, if your privacy settings permit it, we may use your
              profile photo or name when serving an advertisement to a person who is your friend We use your
              non-personal information primarily to ensure that the advertisements we serve are targeted. For example,
              we may aggregate demographic data to create anonymous groups so that we can serve advertisements which may
              appeal to members of those groups.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Generally, Quixo.net uses information about Individuals to target advertising and other information, such
              as business, weather, entertainment, sports and general news alerts and similar information, more
              effectively. When an Individual is online, Quixo.net uses Technology Information to associate an
              Individual with their information, and Quixo.net attempts to show information and advertising for products
              and services to which the person may respond. Quixo.net may use data collected from a response to one type
              of advertising or information for targeting advertisements or other information through a different venue.
              For example, Quixo.net may notice that you responded to a skateboarding-related advertisement and use that
              response to deliver a skateboarding-related advertisement and other information to you.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              In addition, Quixo.net may use Individual personal information to process transactions you have requested
              involving User virtual currencies and to award or credit you with User virtual currencies in connection
              with actions you have taken to respond to advertisements displayed on or in connection with User Web
              Properties. We use Sensitive Information only to process, and provide support, servicing and fraud
              detection for, such transactions and activities.
            </Typography>
            <br />
            <br />
            <Typography variant="h5">Disclosure of Your Personal Information</Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              We do not sell, rent, lease or disclose your personal information to unaffiliated entities, except in the
              limited circumstances listed below. (Quixo.net may transfer information to our affiliated entities, that
              is, entities that control, are controlled by, or are under common control with Quixo.net, for marketing
              and other purposes at our discretion.)
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              We may disclose your personal information to third-party service providers who perform services on our
              behalf. For example, we may engage other companies to handle the processing of payments, to provide data
              storage, to assist in marketing Quixo.net's products or services, to conduct audits, to provide web
              analytics, and to provide hosting, design, development and other operations which make our Site work.
              Other third-party service providers may help us communicate with you through electronic mail, direct mail
              or telephone communication. Our policy is to permit these third-party service providers to obtain only the
              personal information they need to provide the services, and to require them to protect personal
              information to the same extent as Quixo.net, including not using it for any improper purpose.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              We will make your personal information available to non-affiliated third parties only in the following
              limited circumstances:
              <ul>
                <li>
                  When we are compelled to do so by a governmental agency, court or other entity (e.g., to respond to
                  subpoenas, court orders or other legal process);
                </li>
                <li>
                  If we believe your actions violate any law, regulation, our General Terms or any other agreement by
                  which you are bound, or if you threaten the rights, property or safety of Quixo.net, the Site, our
                  service providers, suppliers, customers or users, or any other party;
                </li>
                <li>
                  If, in our sole discretion, we believe such disclosure to be necessary or appropriate to investigate
                  or resolve possible problems or inquiries, to protect our business and assets, to defend our interests
                  and/or to comply with our legal and regulatory obligations;
                </li>
                <li>
                  To cooperate with law enforcement authorities, a court or administrative agency, or other governmental
                  authority in connection with an investigation or where otherwise required or permitted by applicable
                  law.
                </li>
                <li>
                  In the event of a bankruptcy, merger, acquisition, transfer of control, joint venture, sale of assets
                  or other strategic business transaction or event involving us and/or our related companies.
                </li>
              </ul>
            </Typography>
            <br />
            <br />
            <Typography variant="h5">Disclosure of Non-Personal Information</Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              In addition to the foregoing, Quixo.net may sell or transfer non-individualized information, such as
              summary or aggregated anonymous information about all persons or sub-groups of persons. For example, we
              may provide our advertisers with aggregate, anonymous non-personal informational about Individuals,
              including, without limitation, demographic data. This aggregate data allows us to create anonymous groups
              that our advertisers use to identify people who may be interested in particular products or services.
            </Typography>
            <br />
            <br />
            <Typography variant="h5">
              Accessing/Updating/Deleting Your Personal Information and Opting Out of Behavioral Targeting
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              If you would like to delete or update the personal information that we have collected from you, simply
              send your request via e-mail to legal@Quixo.net. If you are an Advertiser on the Quixo.net platform then
              you may update your personal information by logging into your account at our Site and modifying your
              account information.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              We will process your request, where possible and subject to the terms and conditions contained herein,
              within a reasonable period of time after receipt. To protect your privacy and security, we will take
              reasonable steps to help verify your identity before granting access or making corrections.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              To prevent the display of Quixo.net advertising on a Web Property, for example, banner advertisements, do
              not visit the Web Property. To only prevent Quixo.net from tracking your behavior on a Web Property, turn
              off cookies from that Web Property and do not click on or interact with any advertisements. Facebook and
              other social networking platforms also provide you with the ability to change your privacy settings to
              limit the ability of third parties such as Quixo.net to view your profile and other information and track
              your activities and content on those platforms. Quixo.net respects your privacy settings and will not
              collect information in violation of those settings.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              To prevent software programs such as a User social media application from displaying advertisements on
              your computer, uninstall the software program or application provided by the User by following the
              instructions included in the User privacy policy at the time of installation. There should be an option to
              uninstall in the "Add/Remove Programs" section of the Windows&reg; operating system (or the corresponding
              section of the Mac operating systems), or you should be able to do so by visiting the file director of the
              installed program or application and running an uninstaller executable.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              To unsubscribe from Quixo.net or User email lists, click the link included in the footer of any message
              delivered by Quixo.net or a User and follow the instructions provided.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Notwithstanding the foregoing, even if you unsubscribe from marketing emails, we may continue to contact
              you for the purpose of communicating information relating to your participation in the Quixo.net Network,
              as well as to respond to any inquiry or request made by you.
            </Typography>
            <br />
            <br />
            <Typography variant="h5">How We Protect Your Personal Information</Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              We know it takes confidence and trust to share personal information online. We endeavor to keep your
              confidence and maintain your trust by protecting your personal information. To that end, we maintain
              reasonable standards of security and confidentiality. For example, Quixo.net stores Individuals'
              information in a database in encrypted form. In addition, Quixo.net's computers have security measures,
              such as firewalls, to protect against the loss, misuse and/or alteration of the information under
              Quixo.net's control. Quixo.net also maintains physical and procedural safeguards to protect information
              under our control and to restrict access to those employees and agents who have a need to know in order to
              provide services to you and/or operate our business. We also rely as much as possible on reputable
              encrypted third-party payment solutions like PayPal in connection with processing payment transactions
              that may involve Sensitive Information.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Notwithstanding such measures, Quixo.net cannot guarantee that its security measures will prevent these
              computers from being illegally accessed and the Individual information on them stolen or altered. Despite
              our efforts, as with most Internet sites and businesses, it is possible that third parties may unlawfully
              intercept or access transmissions of private communications and/or unlawfully gain access to protected
              computers. No method of data collection, transmission or storage is 100% secure, and we cannot warrant
              such absolute security. Whenever you provide personal information online, you accept this risk, including
              when you share your personal information with us or our Users or in response to an online advertisement.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Notwithstanding these inherent risks, we make a concerted good faith effort to ensure that your personal
              information is protected by maintaining the reasonable standards of security and confidentiality mentioned
              above. We require our service providers who are under contract with us to follow the same policy.
            </Typography>
            <br />
            <br />
            <Typography variant="h5">Privacy Practices of Third Parties</Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Third Party Web Technologies. Users and Advertisers in the Quixo.net Network, as well as other Users and
              advertisers who place advertisements on the Internet, may use their own cookies, web beacons and other Web
              Technologies to collect information about you. Quixo.net has no control over third parties' use of such
              Web Technologies.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Links to Third Party Websites. The Site, Advertiser&rsquo;s advertising content, Users' Web Properties,
              and e-mail messages transmitted by Quixo.net on its own behalf or on behalf of its Users or Advertisers
              may contain links to third-party websites ("Third Party Websites"). Please be aware that Quixo.net is not
              responsible for the privacy and security practices of Third Party Websites. We encourage you to be aware
              when you leave the Site (or when you leave a User's Web Property) and to read the privacy policies of each
              and every website that you visit. This Policy applies SOLELY to information collected by Quixo.net and/or
              the Site.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              In compliance with applicable federal and state laws, we shall notify you and any applicable regulatory
              agencies in the event that we learn of a breach of Quixo.net's information security with respect to your
              personal information protected by those laws. Where permitted by applicable law, you will be notified via
              email in the event of such a breach. Please be advised that notice may be delayed in order to address the
              needs of law enforcement, determine the scope of network damage, and/or to engage in remedial measures.
            </Typography>
            <br />
            <br />
            <Typography variant="h5">Contact Us</Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              If you have any questions regarding this Policy, or would like more information on our privacy practices,
              please contact us at legal@quixo.net.
            </Typography>
            <br />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
