import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
// material
import {
  Grid,
  Container,
  Stack,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Box,
  IconButton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { FormProvider, RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

export default function GenerateBlogOutline() {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { pathname, state: locationState } = useLocation();

  const { title: defaultTitle, keywords: defaultKeywords } = locationState || {};

  const [results, setResults] = useState([]);

  useEffect(() => {
    const json = localStorage.getItem(pathname);
    const savedResults = JSON.parse(json);
    if (savedResults) {
      setResults(savedResults);
    }
  }, [pathname]);

  useEffect(() => {
    const json = JSON.stringify(results);
    localStorage.setItem(pathname, json);
  }, [pathname, results]);

  const { getAccessTokenSilently } = useAuth0();

  const formSchema = Yup.object().shape({
    keywords: Yup.string().required('Keywords field is required'),
    title: Yup.string().required('Title field is required'),
  });

  const defaultValues = {
    title: defaultTitle || '',
    keywords: defaultKeywords || '',
  };

  const methods = useForm({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: 'vrd-aigen/api',
    });

    const { title, keywords } = data;

    const opts = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const body = {
      title,
      keywords,
    };

    await axios
      .post(`${baseUrl}/generate/blog-outline`, body, opts)
      .then((resp) => {
        const { data: response } = resp;
        setResults((prev) => [{ title, keywords, content: response.content, favorite: false }, ...prev]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const setFavorite = (idx, favorite) => {
    setResults((prev) => prev.map((value, i) => (i === idx ? { ...value, favorite } : value)));
  };

  const deleteResult = (idx) => {
    setResults((prev) => prev.filter((_, i) => i !== idx));
  };

  const setValues = (item) => {
    setValue('title', item.title);
    setValue('keywords', item.keywords);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Page title="Generate Blog Outline">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Generate Blog Outline
          </Typography>
        </Stack>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <RHFTextField fullWidth name="title" label="Title" inputRef={formSchema} />
            <RHFTextField fullWidth name="keywords" label="Keywords" inputRef={formSchema} />
          </Stack>
          <br />
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            loadingPosition="start"
            startIcon={<Iconify icon="eva:play-circle-outline" />}
          >
            Generate
          </LoadingButton>
          <br />
        </FormProvider>
        <br />
        <Grid container spacing={2}>
          {results.map((result, index) => (
            <Grid key={`${index}-${result.content}`} item xs={12}>
              <Card variant="outlined">
                <CardHeader
                  title={result.title}
                  subheader={result.keywords}
                 />
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography variant="body" sx={{ flex: '1 0 auto', whiteSpace: 'pre-wrap' }}>
                    {result.content}
                  </Typography>
                </CardContent>
                <CardActions sx={{ display: 'flex' }}>
                  <Box sx={{ flex: 1 }}>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setFavorite(index, !result.favorite);
                      }}
                    >
                      <Iconify icon={`eva:heart-${result.favorite ? 'fill' : 'outline'}`} />
                    </IconButton>
                    <IconButton onClick={() => setValues(result)}>
                      <Iconify icon="eva:edit-fill" />
                    </IconButton>
                  </Box>
                  <IconButton color="error" onClick={() => deleteResult(index)}>
                    <Iconify icon="eva:trash-2-fill" />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
}
