import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Drawer } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Iconify from '../../components/Iconify';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: 0,
  },
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isSidebarOpen: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isSidebarOpen, onCloseSidebar }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

  const navConfig = [
    {
      title: 'Home',
      path: '/#home',
      icon: getIcon('eva:pie-chart-2-fill'),
    },
    {
      title: 'How it works',
      path: '/#features',
      icon: getIcon('eva:people-fill'),
    },
    {
      title: 'Pricing',
      path: '/#pricing',
      icon: getIcon('eva:people-fill'),
    },
    // {
    //   title: 'About Us',
    //   path: '/about-us',
    //   icon: getIcon('eva:people-fill'),
    // }
  ];

  useEffect(() => {
    if (isSidebarOpen) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 5.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isSidebarOpen}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
