// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Ecommerce',
    path: '/dashboard/ecommerce',
    icon: getIcon('eva:shopping-bag-outline'),
    children: [
      {
        title: 'Product Description',
        path: '/dashboard/ecommerce/product-description',
      },
    ]
  },
  {
    title: 'Blog Content',
    path: '/dashboard/blog',
    icon: getIcon('eva:book-outline'),
    children: [
      {
        title: 'Blog Title',
        path: '/dashboard/blog/title',
      },
      {
        title: 'Blog Intro',
        path: '/dashboard/blog/intro',
      },
      {
        title: 'Blog Outline',
        path: '/dashboard/blog/outline',
      },
    ]
  },
  {
    title: 'Rewrite',
    path: '/dashboard/rewrite',
    icon: getIcon('eva:file-text-outline'),
    children: [
      {
        title: 'Summarize',
        path: '/dashboard/rewrite/summarize',
      },
      {
        title: 'Explain It',
        path: '/dashboard/rewrite/explain-it',
      },
      {
        title: 'Extract Keywords',
        path: '/dashboard/rewrite/extract-keywords',
      },
    ]
  },
];

export default navConfig;
