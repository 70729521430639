import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button, Avatar } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
import Logo from '../../components/Logo';
// hooks
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Navbar.propTypes = {
  onOpenSidebar: PropTypes.func,
  onCloseSidebar: PropTypes.func,
};

export default function Navbar({ onOpenSidebar, onCloseSidebar }) {
  const { loginWithRedirect, isAuthenticated, user, isLoading } = useAuth0();

  const isDesktop = useResponsive('up', 'lg');

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      window.scrollTo({
        behavior: 'smooth',
        top:
          document.querySelector(location.hash).getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          (APPBAR_MOBILE * 2),
      });
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <RootStyle>
      <ToolbarStyle>
        <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
          <Logo />
        </Box>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        {isDesktop && (
          <>
            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              <Button component={RouterLink} to="/#home">
                Home
              </Button>
              <Button component={RouterLink} to="/#features">
                How it works
              </Button>
              <Button component={RouterLink} to="/#pricing">
                Pricing
              </Button>
              {/* <Button component={RouterLink} to="/about-us">
                About Us
              </Button> */}
            </Stack>

            <Box sx={{ flexGrow: 1 }} />
          </>
        )}

        {!isAuthenticated && !isLoading && (
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <Button onClick={loginWithRedirect} variant="outlined">
              Login
            </Button>
            <Button onClick={() => loginWithRedirect({ screen_hint: 'signup' })} variant="contained">
              Sign up
            </Button>
          </Stack>
        )}
        {isAuthenticated && !isLoading && (
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <Button component={RouterLink} to="/dashboard/app">
              <Avatar src={user.picture} alt="avatar" />
              {user.nickname}
            </Button>
          </Stack>
        )}
      </ToolbarStyle>
    </RootStyle>
  );
}
