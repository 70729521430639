// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Card, CardActions, CardContent, Container, Divider, Grid, Typography } from '@mui/material';
// components
import Page from '../components/Page';

// sections

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  // maxWidth: 600,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'top',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));

// ----------------------------------------------------------------------

export default function LandingPage() {
  return (
    <Page title="Home">
      <RootStyle>
        <Container maxWidth="lg">
          <ContentStyle>
            <Grid container spacing={3}>
              <Grid item lg={12}>
                <div id="home" />
                <Typography variant="h4" gutterBottom>
                  Create original, AI-assisted content in seconds.
                </Typography>

                <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                  With our AI powered engine, you can create high-quality, original content for your blog, website,
                  social media, email, and more. Say goodbye to writer's block.
                </Typography>
                <Divider />
              </Grid>

              <Grid item lg={12}>
                <div id="features">
                  <Typography variant="h5" gutterBottom>
                    How it works?
                  </Typography>
                  <Typography sx={{ color: 'text.secondary', mb: 1 }}>
                    Our bot can help you with every step of content generation, from brainstorming and finding ideas to
                    finalizing them. You can generate quality content by following these three easy steps:
                  </Typography>
                  <Typography sx={{ color: 'text.secondary', mb: 1 }}>
                    <ul>
                      <li>Enter the keywords you want to target</li>
                      <li>Select the content type you need</li>
                      <li>Let our bot do the rest!</li>
                    </ul>
                  </Typography>
                  <Typography sx={{ color: 'text.secondary', mb: 1 }}>
                    If you're in need of high-quality, original content and you want it fast, our content generator is
                    the perfect solution! Just select from the content options and our AI-powered bot will help you
                    through every stage of the writing process. You could be enjoying your new content in just seconds.
                  </Typography>
                  <Typography sx={{ color: 'text.secondary', mb: 1 }}>
                    <strong>Why use a content generator?</strong>
                  </Typography>
                  <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                    There are many reasons you might need to use a content generator. Maybe you're a busy entrepreneur
                    who doesn't have time to write blog posts or create social media content. Maybe you need to create
                    content for a website or app and you don't have the resources to do it yourself. Or maybe you're
                    just looking for a quick, easy way to create high-quality content. Generating content has never been
                    easier. Whatever your reason, our content generator can help. It's fast, easy to use, and best of
                    all, it produces original, high-quality content. So why wait? Get started today!
                  </Typography>
                  <Divider />
                </div>
              </Grid>

              <Grid item lg={12}>
                <div id="pricing">
                  <Typography variant="h5" gutterBottom>
                    Pricing
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} md={4}>
                      <Card variant="outlined" sx={{ display: 'flex' }}>
                        <Box sx={{ flexDirection: 'column', flex: '1' }}>
                          <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography variant="body">
                              Trial
                            </Typography>
                            <Typography variant="h2" gutterBottom>
                              FREE
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                              Try 7 days for free
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              <ul>
                                <li>dsfdsfdsf</li>
                                <li>dsfdsfdsf</li>
                                <li>dsfdsfdsf</li>
                                <li>dsfdsfdsf</li>
                                <li>dsfdsfdsf</li>
                              </ul>
                              <Button size="small">Learn More</Button>
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button color="primary" fullWidth variant="outlined">Choose plan</Button>
                          </CardActions>
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Card variant="outlined" sx={{ display: 'flex' }}>
                        <Box sx={{ flexDirection: 'column', flex: '1' }}>
                          <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography variant="body">
                              Standard
                            </Typography>
                            <Typography variant="h2" gutterBottom>
                              $29
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                              billed monthly
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              <ul>
                                <li>dsfdsfdsf</li>
                                <li>dsfdsfdsf</li>
                                <li>dsfdsfdsf</li>
                                <li>dsfdsfdsf</li>
                                <li>dsfdsfdsf</li>
                              </ul>
                              <Button size="small">Learn More</Button>
                            </Typography>
                          </CardContent>
                          <CardActions>
                          <Button color="primary" fullWidth variant="contained">Choose plan</Button>
                          </CardActions>
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Card variant="outlined" sx={{ display: 'flex' }}>
                        <Box sx={{ flexDirection: 'column', flex: '1' }}>
                          <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography variant="body">
                              Pro
                            </Typography>
                            <Typography variant="h2" gutterBottom>
                              $99
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                              billed monthly
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              <ul>
                                <li>dsfdsfdsf</li>
                                <li>dsfdsfdsf</li>
                                <li>dsfdsfdsf</li>
                                <li>dsfdsfdsf</li>
                                <li>dsfdsfdsf</li>
                              </ul>
                              <Button size="small">Learn More</Button>
                            </Typography>
                          </CardContent>
                          <CardActions>
                          <Button color="primary" fullWidth variant="outlined">Choose plan</Button>
                          </CardActions>
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
