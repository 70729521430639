import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Select, MenuItem, FormControl, FormHelperText, InputLabel } from '@mui/material';

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
};

export default function RHFSelect({ name, label, options, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      fullWidth
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <InputLabel>{label}</InputLabel>
          <Select
            {...field}
            label={label}
            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
            error={!!error}
            {...other}
          >
            {options.map((o) => (
              <MenuItem key={o.value} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
          {!!error && <FormHelperText error={!!error}>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
