import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 2048.0 2048.0">
        <defs>
          <linearGradient id="BG" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT}/>
            <stop offset="85%" stopColor={PRIMARY_MAIN}/>
            <stop offset="100%" stopColor={PRIMARY_DARK}/>
          </linearGradient>
        </defs>
        <g id="document" fill={PRIMARY_MAIN} fillRule="evenodd" transform="matrix(1,0,0,1,1024.0,1024.0)">
          <path
            fill="url(#BG)"
            fillOpacity="1.00"
            d="M-573.902,-37.8104 C-573.902,279.764 -317.574,533.823 1.08165e-05,530.019 L1.08165e-05,270.69 C-170.129,270.69 -308.501,132.319 -308.501,-37.8104 L-308.501,-223.954 L-573.902,-223.954 L-573.902,-37.8104 Z M-3.24495e-05,-795.42 C-317.574,-795.42 -571.634,-539.092 -573.902,-223.954 L-308.501,-223.954 C-308.501,-391.647 -170.129,-530.019 -3.24495e-05,-530.019 L-3.24495e-05,-795.42 Z M1.08165e-05,-795.42 L1.08165e-05,-530.019 C170.129,-530.019 308.501,-394.083 308.501,-223.954 L573.902,-223.954 C571.634,-541.528 317.574,-795.42 1.08165e-05,-795.42 Z M573.902,223.954 L573.902,-223.954 L308.501,-223.954 L308.501,223.954 C308.501,394.083 170.129,530.019 1.08165e-05,530.019 L1.08165e-05,795.42 C317.574,795.42 573.902,541.528 573.902,223.954 Z"/>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
